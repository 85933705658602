import { createRouter, createWebHistory } from "vue-router";
import Swal from 'sweetalert2';
import Acceuil from "../views/Acceuil.vue";
import PaymentLink from "../views/PaymentLink.vue";
import Client from "../views/clients/Client.vue";
import Apercu from "../views/clients/Apercu.vue";
import DetailsTransaction from "../views/clients/DetailsTransaction.vue";
import Dossier from "../views/clients/Dossier.vue";
import NumeroPaie from "../views/clients/NumeroPaie.vue";
import Parametre from "../views/clients/Parametre.vue";
import Profile from "../views/clients/Profile.vue";
import Transaction from "../views/clients/Transaction.vue";
import Mesgestionnaire from "../views/clients/Mesgestionnaire.vue";
import Gerer from "../views/clients/Gerer.vue";
import Caise from "../views/clients/Caise.vue";
import Test from "../views/Test.vue";
import Inscription from "../views/Inscription.vue";
import MotdepasseOublier from "../views/MotdepasseOublier.vue";
import ListeMarchand from "../views/ListeMarchand.vue";
import PayQr from "../views/PayQr.vue";
import VerifyAccount from "../views/VerifyAccount.vue";
import PayLink from "../views/PayLink.vue";
import RecuDePaiement from "../views/RecuDePaiement.vue";
import Connexion from "../views/Connexion.vue";
import Cont from "../views/Cont.vue";
import Verification from "../views/Verification.vue";
import InscriptionPayLinkUser from "../views/InscriptionPayLinkUser.vue";
import EEngagement from "../views/EEngagement.vue";


import DossierTransaction from "@/views/clients/DossierTransaction.vue";
import Expediteur from "@/views/clients/Expediteur.vue";
import BonusTransaction from "@/views/clients/BonusTransaction.vue";
import ReseauGsm from "@/views/clients/ReseauGsm.vue";
import DossierDetails from "@/views/clients/DossierDetails.vue";
import DebiteurDetails from "@/views/clients/DebiteurDetails.vue";
import Suivie from "@/views/clients/Suivie.vue";
import DossierTransac from "@/views/DossierTransac.vue";
import ExportTransaction from "@/views/clients/ExportTransaction.vue";
import Pointage from "@/views/clients/Pointage.vue";

import Admin from "@/views/admin/Admin.vue";
import AjouteEntreprise from "@/views/admin/AjouteEntreprise.vue";
import MesEntreprise from "@/views/admin/MesEntreprise.vue";
import Entite from "@/views/admin/Entite.vue";
import Paiements from "../views/Paiements.vue";

const routes = [
  {
    path: "/",
    name: "acceuil",
    component: Acceuil,
  },

  {
    path: "/paiements",
    name: "paiements",
    component: Paiements,
  },

  {
    path: "/verification",
    name: "verification",
    component: Verification,
  },

  {
    path: "/paymentlink",
    name: "paymentlink",
    component: PaymentLink,
  },


  {
    path: "/account-verification",
    name: "account-verification",
    component: VerifyAccount,
  },


  {
    path: "/mot-de-passe-oublier",
    name: "mot-de-passe-oublier",
    component: MotdepasseOublier,
  },

  {
    path: "/dossier-transac",
    name: "dossier-transac",
    component: DossierTransac,
  },

  {
    path: "/test",
    name: "test",
    component: Test,
  },

  {
    path: "/connexion",
    name: "connexion",
    component: Connexion,
  },

  {
    path: "/inscription",
    name: "inscription",
    component: Inscription,
  },

  {
    path: "/listes-marchands",
    name: "listes-marchands",
    component: ListeMarchand,
  },

  {
    path: "/paylink/:id",
    name: "paylink",
    component: PayLink,
  },

  {
    path: "/payqr",
    name: "payqr",
    component: PayQr,
  },

  {
    path: "/recu-paiement",
    name: "recu-paiement",
    component: RecuDePaiement,
  },

  {
    path: "/paylink-user-registration",
    name: "paylink-user-registration",
    component: InscriptionPayLinkUser,
  },

  {
    path: "/e-engagement",
    name: "e-engagement",
    component: EEngagement,
  },

  //ROUTE POUR LES CLIENTS

  {
    path: "/clients/client",
    name: "client",
    component: Client,
  },

  {
    path: "/clients/detailstransaction",
    name: "detailstransaction",
    component: DetailsTransaction,
  },

  {
    path: "/clients/dossier",
    name: "dossier",
    component: Dossier,
  },

  {
    path: "/clients/dossier-transaction",
    name: "dossier-transaction",
    component: DossierTransaction,
  },

  {
    path: "/clients/expediteur-transaction",
    name: "expediteur-transaction",
    component: Expediteur,
  },

  {
    path: "/clients/bonus-transaction",
    name: "bonus-transaction",
    component: BonusTransaction,
  },

  {
    path: "/clients/reseau-gsm",
    name: "reseau-gsm",
    component: ReseauGsm,
  },

  {
    path: "/clients/numeropaie",
    name: "numeropaie",
    component: NumeroPaie,
  },

  {
    path: "/clients/parametre",
    name: "parametre",
    component: Parametre,
  },

  {
    path: "/clients/profile/:id",
    name: "profile",
    component: Profile,
  },

  {
    path: "/clients/transaction",
    name: "transaction",
    component: Transaction,
  },

  {
    path: "/clients/apercu",
    name: "apercu",
    component: Apercu,
  },

  {
    path: "/clients/dossier-details/:id",
    name: "dossier-details",
    component: DossierDetails,
  },

  {
    path: "/clients/debiteur-details/:id",
    name: "debiteur-details",
    component: DebiteurDetails,
  },

  {
    path: "/clients/mesgestionnaire",
    name: "mesgestionnaire",
    component: Mesgestionnaire,
  },

  {
    path: "/clients/gerer",
    name: "gerer",
    component: Gerer,
  },

  {
    path: "/clients/suivi",
    name: "suivi",
    component: Suivie,
  },

  {
    path: "/clients/caise",
    name: "caise",
    component: Caise,
  },

  {
    path: "/clients/export-transaction",
    name: "export-transaction",
    component: ExportTransaction,
  },


  // ROUTE POUR L'ADMINISTRATEUR

  {
    path: "/admin/admin",
    name: "admin",
    component: Admin,
  },

  {
    path: "/admin/ajouter-entreprise",
    name: "ajouter-entreprise",
    component: AjouteEntreprise,
  },

  {
    path: "/admin/mes-entreprise",
    name: "mes-entreprise",
    component: MesEntreprise,
  },

  {
    path: "/admin/mes-entites",
    name: "mes-entites",
    component: Entite,
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = ['acceuil', 'connexion', 'inscription', 'verification', 'mot-de-passe-oublier', 'listes-marchands', 'paymentlink', 'payqr', 'pay', 'paiements','recu-paiement','paylink-user-registration','e-engagement','account-verification','paylink'];
  const isPublicPage = publicPages.includes(to.name);
  const token = sessionStorage.getItem('authToken');

  if (!isPublicPage && !token) {
    Swal.fire({
      icon: 'warning',
      title: 'Vous devez d\'abord vous connecter',
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      customClass: {
        toast: 'swal2-toast',
      },
    });
    next('/connexion');
  } else {
    next();
  }
});

export default router;
