<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-#006666 py-3 fixed-top">
        <div class="container px-5">
            <a class="navbar-brand" href="/">
                <h3 class="text-white"> AfricaMoney </h3>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0 small fw-bolder">
                    <li v-if="!hasAuthToken" class="nav-item">
                        <a class="nav-link text-white fw-bold" href="/">Accueil</a>
                    </li>
                    <li v-if="!hasAuthToken" class="nav-item">
                        <a class="nav-link text-white fw-bold" href="#">E-MoMo</a>
                    </li>
                    <li v-if="!hasAuthToken" class="nav-item">
                        <a class="nav-link text-white fw-bold" href="#">PayLink</a>
                    </li>
                    <li v-if="!hasAuthToken" class="nav-item">
                        <a class="nav-link text-white fw-bold" href="/e-engagement">E-engagement</a>
                    </li>
                    <!-- <li v-if="!hasAuthToken" class="nav-item">
                       <a class="nav-link text-white fw-bold" href="#/listes-marchands">Marchands</a>
                     </li>
                     <li v-if="!hasAuthToken" class="nav-item">
                       <a class="nav-link text-white fw-bold" href="/verification">Reçu</a>
                     </li> -->
                    <li v-if="!hasAuthToken" class="nav-item">
                        <a class="nav-link text-white fw-bold" href="#">Contact</a>
                    </li>

                    <li v-if="hasAuthToken" class="nav-item">
                        <button @click="logoutUser" class="btn btn-danger text-white">
                            Déconnexion
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <br /><br />
</template>

<script>
import axios from "@/axs/axios.js";
import Swal from 'sweetalert2';
import { useRouter } from 'vue-router';

export default {
    computed: {
        hasAuthToken() {
            return !!sessionStorage.getItem('authToken');
        }
    },
    methods: {
        async logoutUser() {
            try {
                const response = await axios.post('/logout', null, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
                    },
                });

                if (response.status === 200) {
                    console.log('Déconnexion réussie.');
                    // Effacer le token de sessionStorage
                    sessionStorage.removeItem('authToken');
                    // Afficher un toast de déconnexion réussie
                    Swal.fire({
                        icon: 'success',
                        title: 'Déconnexion réussie',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000 // Durée du toast en millisecondes (3 secondes)
                    });
                    this.$router.push('/').then(() => {
                        // Rafraîchir la page après la redirection
                        window.location.reload();
                    });
                } else {
                    console.error('Échec de la déconnexion. Réponse de l\'API:', response.data);
                }
            } catch (error) {
                console.error('Erreur lors de la requête de déconnexion:', error);
            }
        },
    }
}
</script>

<style></style>
