<template>
    <div>
        <Header />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
                <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Recherche</h5>
                                <div class="row mb-4">
                                    <div class="col-md-3">
                                        <label for="startDate">Date</label>
                                        <input type="date" id="startDate" class="form-control">
                                    </div>
                                    <div class="col-md-2">
                                        <label for="searchNumber">Numéro</label>
                                        <input type="text" class="form-control" id="searchNumber">
                                    </div>
                                    <div class="col-md-4">
                                        <label for="searchDestinataire">Expéditeur</label>
                                        <input type="text" class="form-control" id="searchDestinataire">
                                    </div>
                                </div>
                                <button type="button" class="btn btn-sm btn-warning" data-bs-toggle="modal" data-bs-target="#ajouterPayLinksModal">
                                    <i class="fas fa-plus"></i> Ajouter un PayLink
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Mes PayLinks</h5>
                                <div class="table-responsive">
                                    <table class="account-table table">
                                        <thead style="background-color: #006666;">
                                            <tr>
                                                <th scope="col" style="color: white;">N°</th>
                                                <th scope="col" style="color: white;">DATE</th>
                                                <th scope="col" style="color: white;">PAYLINKS</th>
                                                <th scope="col" style="color: white;">MONTANT</th>
                                                <th scope="col" style="color: white;">MODE</th>
                                                <th scope="col" style="color: white;">NUMERO DE DEPOT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(payLink, index) in paginatedPayLinks" :key="payLink.id">
                                                <td>{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                                                <td>{{ formatDate(payLink.created_at) }}</td>
                                                <td>{{ payLink.nom }}</td>
                                                <td>{{ payLink.montant }}</td>
                                                <td>{{ payLink.mode }}</td>
                                                <td>{{ payLink.deposit_number }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="pagination">
                                    <button class="btn btn-primary" @click="changePage(currentPage - 1)" :disabled="currentPage === 1">«</button>
                                    <p v-for="page in totalPages" :key="page" @click="changePage(page)" :class="{ active: page === currentPage }">{{ page }}</p>
                                    <button class="btn btn-warning" @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages">»</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade modal-lg" id="ajouterPayLinksModal" tabindex="-1" aria-labelledby="ajouterPayLinksModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="ajouterPayLinksModalLabel">Ajouter un PayLink</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="submitPayLink">
                            <div class="mb-3">
                                <label for="nom" class="form-label">Nom</label>
                                <input type="text" class="form-control" id="nom" v-model="payLink.nom" required>
                            </div>
                            <div class="mb-3">
                                <label for="objet" class="form-label">Objet</label>
                                <input type="text" class="form-control" id="objet" v-model="payLink.objet" required>
                            </div>
                            <div class="mb-3">
                                <label for="montant" class="form-label">Montant</label>
                                <input type="number" step="0.01" class="form-control" id="montant" v-model="payLink.montant" required>
                            </div>
                            <div class="mb-3">
                                <label for="montant_min" class="form-label">Montant Min</label>
                                <input type="number" step="0.01" class="form-control" id="montant_min" v-model="payLink.montant_min">
                            </div>
                            <div class="mb-3">
                                <label for="montant_max" class="form-label">Montant Max</label>
                                <input type="number" step="0.01" class="form-control" id="montant_max" v-model="payLink.montant_max">
                            </div>

                            <div class="mb-3">
                                <label for="mode" class="form-label">Mode</label>
                                <select class="form-select" id="status" v-model="payLink.mode" required>
                                    <option value="public">Public</option>
                                    <option value="prive">Privé</option>
                                </select>
                            </div>

                            <div class="mb-3">
                                <label for="description" class="form-label">Description</label>
                                <textarea class="form-control" id="description" v-model="payLink.description"></textarea>
                            </div>
                        
                            <div class="mb-3">
                                <label for="expiration_at" class="form-label">Expiration</label>
                                <input type="date" class="form-control" id="expiration_at" v-model="payLink.expiration_at">
                            </div>
                            <div class="mb-3">
                                <label for="deposit_number" class="form-label">Numéro de Dépôt</label>
                                <input type="text" class="form-control" id="deposit_number" v-model="payLink.deposit_number">
                            </div>
                            <div class="mb-3">
                                <label for="withdrawal_number" class="form-label">Numéro de Retrait</label>
                                <input type="text" class="form-control" id="withdrawal_number" v-model="payLink.withdrawal_number">
                            </div>
                            <div class="mb-3">
                               <label for="image" class="form-label">Image</label>
                               <input type="file" class="form-control" id="image" @change="handleFileUpload">
                           </div>

                            <button type="submit" class="btn btn-primary">Enregistrer</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            payLink: {
                nom: '',
                objet: '',
                montant: null,
                montant_min: null,
                montant_max: null,
                description: '',
                mode: '',
                expiration_at: null,
                deposit_number: '',
                withdrawal_number: '',
                image: null
            },
            payLinks: [],
            currentPage: 1,
            itemsPerPage: 10
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.payLinks.length / this.itemsPerPage);
        },
        paginatedPayLinks() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.payLinks.slice(start, end);
        }
    },
    methods: {
        handleFileUpload(event) {
            this.payLink.image = event.target.files[0];
        },
        submitPayLink() {
            const formData = new FormData();
            
            // Ajoutez toutes les données de payLink au FormData
            Object.keys(this.payLink).forEach(key => {
                formData.append(key, this.payLink[key]);
            });

            // Récupérer l'ID de additional_info depuis le sessionStorage
            const userData = JSON.parse(sessionStorage.getItem('userData'));
            const clientId = userData.additional_info.id;
            formData.append('client_id', clientId);

            axios.post(`/add-paylinks`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                Swal.fire('Succès', 'PayLink enregistré avec succès', 'success');
                window.location.reload();
                this.resetForm();
                this.fetchPayLinks();  // Recharger les PayLinks après ajout
            })
            .catch(error => {
                Swal.fire('Erreur', 'Une erreur est survenue lors de l\'enregistrement du PayLink', 'error');
            });
        },
        resetForm() {
            this.payLink = {
                nom: '',
                objet: '',
                montant: null,
                montant_min: null,
                montant_max: null,
                description: '',
                mode: '',
                expiration_at: null,
                deposit_number: '',
                withdrawal_number: '',
                image: null
            };
        },
        fetchPayLinks() {
            // Récupérer l'ID de additional_info depuis le sessionStorage
            const userData = JSON.parse(sessionStorage.getItem('userData'));
            const clientId = userData.additional_info.id;
            
            axios.get(`/paylinks/${clientId}`)
                .then(response => {
                    this.payLinks = response.data.data;
                })
                .catch(error => {
                    console.error('Erreur lors de la récupération des PayLinks:', error);
                });
        },
        formatDate(dateString) {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            return new Date(dateString).toLocaleDateString(undefined, options);
        },
        changePage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
            }
        }
    },
    created() {
        this.fetchPayLinks();  // Charger les PayLinks lors de la création du composant
    }
};
</script>


<style>
.file-upload-btn {
    display: inline-block;
    padding: 8px 12px;
    background-color: #006666;
    color: rgb(255, 255, 255);
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.file-upload-btn:hover {
    background-color: #004d40;
}
</style>