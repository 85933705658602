<template>
    <main class="main">

        <Head />
        <div class="container">
            <div class="row justify-content-center align-items-center min-vh-100 py-5">
                <div class="col-md-10 col-lg-6 col-xl-5">
                    <div class="shadow p-3 mb-5 bg-body rounded">
                        <div>
                            <div class="text-center mb-7">
                                <i class="bi bi-shield-check" style="font-size: 3rem; color: #006666;"></i>
                                <h3 class="text-1000 mt-2">ENTRER LE CODE DE VÉRIFICATION</h3>
                            </div>
                            <form @submit.prevent="verifyCode">
                                <div class="mb-3 text-start">
                                    <div class="form-icon-container">
                                        <input class="form-control form-icon-input" v-model="verificationCode"
                                            type="text" id="verificationCode" placeholder="Entrez le code reçu" required
                                            pattern="\d+" />
                                    </div>
                                </div>
                                <button class="btn btn-secondary w-100 mb-3" style="background-color: #006666;"
                                    type="submit" :disabled="loading">
                                    <span v-if="loading" class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span v-else>Vérifier le code</span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <footer class="py-4 mt-auto" style="background-color: #006666; color: white;">
        <div class="container">
            <div
                class="row align-items-center justify-content-center justify-content-sm-between text-center text-sm-start">
                <div class="col-auto">
                    <div class="small m-0" v-text="'© Africamoney ' + new Date().getFullYear()"></div>
                </div>
                <div class="col-auto">
                    <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Confidentialité</a>
                    <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Terms et conditions</a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import axios from "@/axs/axios.js";
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import Head from "@/components/Head.vue";

export default {
    components: { Head },
    data() {
        return {
            verificationCode: '',
            loading: false,
        };
    },
    methods: {
        async verifyCode() {
            if (!/^\d+$/.test(this.verificationCode)) {
                Swal.fire({
                    icon: 'error',
                    title: 'Code invalide',
                    text: 'Le code de vérification doit contenir uniquement des chiffres.',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                });
                return;
            }

            try {
                this.loading = true;
                const response = await axios.post('/verify-code', {
                    code: this.verificationCode,
                });

                if (response.data.success) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Vérification réussie',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    });
                    this.$router.push('/dashboard');
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Code invalide',
                        text: 'Le code saisi est incorrect. Veuillez réessayer.',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    });
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Erreur de requête',
                    text: 'Une erreur est survenue. Veuillez réessayer plus tard.',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                });
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>