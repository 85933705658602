<script setup>
import { ref, onMounted, computed, reactive, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import { defineProps } from "vue";
import axios from "@/axs/axios.js";

const props = defineProps(["agenceSlug"]);
const store = useStore();

const serviceItems = ref([]);
const selectedTab = ref("MTN");
const isProcessing = ref(false);
const isProcessingPayment = ref(false);
//mtn paiement
const validateAndPrefixNumber = () => {
  if (!payer.value.partyId.startsWith("229")) {
    payer.value.partyId = `229${payer.value.partyId}`;
  }
};

const validateForm = () => {
  return (
    nompre.value &&
    email.value &&
    payer.value.partyId &&
    calculateTotalPriceWithTaux.value > 0
  );
};

const isFormValid = computed(() => validateForm());
const amount = ref("");
const currency = ref("XOF");
const externalId = ref("12345678");
const email = ref("");
const nompre = ref("");
const payer = ref({
  partyIdType: "MSISDN",
  partyId: "",
});
const paymentReferenceId = ref("");
const payerMessage = ref("");
const payeeNote = ref("");
const config = ref({});

store.watch(
  () => store.getters["getConfig"],
  (newConfig) => {
    config.value = newConfig;
  }
);

const calculateTotalPrice = computed(() => {
  let totalPrice = 0;
  serviceItems.value.forEach((item) => {
    totalPrice += item.quantity * item.service.prixService;
  });
  return totalPrice;
});

// Calculer le prix total avec le taux
const calculateTotalPriceWithTaux = computed(() => {
  return 1;
});

const taux = computed(() => {
  let totalPrice = calculateTotalPrice.value;
  if (config.value.taux && !isNaN(config.value.taux)) {
    totalPrice = totalPrice * (parseFloat(config.value.taux) / 100);
  }
  return Math.round(totalPrice);
});

const alertMessage = computed(() => {
  if (config.value.taux && !isNaN(config.value.taux)) {
    return `${config.value.taux} % de frais de transaction ont été ajoutés au montant. Frais : ${taux.value} FCFA. Prix total : ${calculateTotalPriceWithTaux.value} FCFA`;
  }
  return "";
});

const processPayment = async () => {
  isProcessingPayment.value = true;

  if (!validateForm()) {
    alertMessage.value = "Veuillez remplir tous les champs du formulaire.";
    isProcessingPayment.value = false;
    return;
  }

 

  try {
    amount.value = calculateTotalPriceWithTaux.value.toString();
    const data = {
      amount: amount.value,
      currency: currency.value,
      externalId: externalId.value,
      payer: payer.value,
      email: email.value,
      nompre: nompre.value,
      payerMessage: payerMessage.value,
      payeeNote: payeeNote.value,
    };
console.log(data);
    const paymentResponse = await axios.post("/process-payment", data);

    if (paymentResponse.status === 202 || paymentResponse.status === 200) {
      paymentReferenceId.value = paymentResponse.data.paiement.referenceId;
      useSwalSuccess("Paiement initialisé.Veuillez confirmer le paiement!");
      startCheckingStatus(paymentReferenceId.value);
    } else {
      handleErrorResponse(paymentResponse);
    }
  } catch (error) {
    console.error("Erreur lors du paiement :", error);
    Swal.fire({
      icon: "error",
      title: "Erreur de paiement",
      text: "Une erreur s'est produite lors du traitement de votre paiement. Veuillez réessayer.",
    });
  }
};


const resetForm = () => {
  amount.value = "";
  currency.value = "";
  externalId.value = "";
  payer.value = "";
  email.value = "";
  nompre.value = "";
  payerMessage.value = "";
  payeeNote.value = "";
  paymentReferenceId.value = "";
  isProcessing.value = false;
};

const paymentPhone = ref("");

const handleErrorResponse = (response) => {
  let errorMessage = "Une erreur inconnue s'est produite.";

  if (response.status === 400) {
    errorMessage = "Requête invalide. Veuillez vérifier les données saisies.";
  } else if (response.status === 404) {
    errorMessage = "Référence introuvable. Veuillez réessayer.";
  } else if (response.status === 500) {
    errorMessage = "Erreur interne du serveur. Veuillez réessayer plus tard.";
  }

  Swal.fire({
    icon: "error",
    title: "Erreur de paiement",
    text: errorMessage,
  });
};
</script>

<template>
  <section class="pt-0">
    <div class="container">
      <div class="row">
        <!-- Right side START -->
        <div class="col-lg-4">
          <div class="row">
            <div>
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{
                      active: selectedTab === 'MTN',
                      'bg-success': selectedTab === 'MTN',
                    }"
                    @click.prevent="selectedTab = 'MTN'"
                    href="#"
                  >
                    <img
                      src="/images/mtn-new-logo.svg"
                      alt="MTN"
                      style="height: 30px; margin-right: 10px"
                    />
                    MTN
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{
                      active: selectedTab === 'Moov',
                      'bg-success': selectedTab === 'Moov',
                    }"
                    @click.prevent="selectedTab = 'Moov'"
                    href="#"
                  >
                    <img
                      src="/images/images.png"
                      alt="Moov"
                      style="height: 30px; margin-right: 10px"
                    />
                    Moov
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{
                      active: selectedTab === 'Celtis',
                      'bg-success': selectedTab === 'Celtis',
                    }"
                    @click.prevent="selectedTab = 'Celtis'"
                    href="#"
                  >
                    <img
                      src="/images/images (1).png"
                      alt="Celtis"
                      style="height: 30px; margin-right: 10px"
                    />
                    Celtis
                  </a>
                </li>
              </ul>
              <div class="tab-content">
                <div
                  v-if="selectedTab === 'MTN'"
                  class="tab-pane fade show active"
                >
                  <div
                    v-if="alertMessage"
                    class="alert alert-success mt-2"
                    role="alert"
                  >
                    {{ alertMessage }}
                  </div>

                  <div class="form-group">
                    <label for="nompre" class="mb-1"
                      ><i class="fas fa-user"></i> Nom et Prénoms
                    </label>
                    <input type="text" v-model="nompre" class="form-control" />
                  </div>

                  <div class="form-group mt-3">
                    <label for="email" class="mb-1"
                      ><i class="fas fa-envelope"></i> Email
                    </label>
                    <input type="email" v-model="email" class="form-control" />
                  </div>

                  <input
                    type="text"
                    v-model="calculateTotalPriceWithTaux"
                    class="form-control text-center"
                    id="montant"
                    placeholder=" "
                    disabled
                    hidden
                  />

                  <div class="form-group mt-3">
                    <label for="tel" class="mb-1"
                      ><i class="fas fa-phone"></i> Compte Mobile Money
                    </label>
                    <div class="input-group">
                      <span class="input-group-text"
                        ><i class="fi fi-bj"></i
                      ></span>
                      <input
                        type="text"
                        v-model="payer.partyId"
                        @blur="validateAndPrefixNumber"
                        class="form-control"
                        id="tel"
                        placeholder=" Ex: 22966445878 "
                        required
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="selectedTab === 'Moov'"
                  class="tab-pane fade show active"
                >
                  <div
                    v-if="alertMessage"
                    class="alert alert-warning mt-2 text-center"
                    role="alert"
                  >
                    Non disponible
                  </div>
                </div>
                <div
                  v-if="selectedTab === 'Celtis'"
                  class="tab-pane fade show active"
                >
                  <div
                    v-if="alertMessage"
                    class="alert alert-warning mt-2 text-center"
                    role="alert"
                  >
                    Non disponible
                  </div>
                </div>
                <button
                  type="button"
                  class="btn btn-warning text-white"
                  @click="processPayment"
                  :disabled="!isFormValid || isProcessingPayment"
                >
                  <span v-if="!isProcessingPayment">
                    <i class="fas fa-money-bill-wave"></i>
                    Payer {{ calculateTotalPriceWithTaux }} FCFA
                  </span>
                  <span v-else>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Traitement...
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Right side END -->
      </div>
    </div>
  </section>
</template>
  
<style scoped>

</style>
  