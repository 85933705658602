<template>
  <main class="main">
    <br/><br/> <br/><br/> <br/><br/> <br/><br/>
    <div class="container">
      <div class="row justify-content-center align-items-center min-vh-50 py-3">
        <div class="shadow p-3 mb-4 bg-body rounded">
          <div class="text-center mb-4">
            <i class="fas fa-sign-in-alt fa-2x text-1000 mb-2"></i>
            <h3 class="text-1000">Connexion</h3>
          </div>
          
          <!-- Formulaire d'envoi du code -->
          <div v-if="step === 1">
            <div class="alert alert-info" role="alert">
              <h5 class="alert-heading">
                <i class="fas fa-envelope fa-sm"></i> Veuillez entrer votre e-mail pour recevoir un code de connexion.
              </h5>
            </div>
            <form @submit.prevent="requestLoginCode">
              <div class="mb-3">
                <label for="email" class="form-label">E-mail</label>
                <input 
                  v-model="email" 
                  type="email" 
                  class="form-control" 
                  id="email" 
                  placeholder="name@example.com" 
                />
              </div>
              <button 
                type="submit" 
                class="btn btn-secondary" 
                style="background-color: #006666;" 
                :disabled="loading"
              >
                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-else>Envoyer le code</span>
              </button>
            </form>
          </div>

          <!-- Formulaire de connexion avec code -->
          <div v-if="step === 2">
            <div class="alert alert-info" role="alert">
              <h5 class="alert-heading">
                <i class="fas fa-key fa-sm"></i> Veuillez entrer le code de connexion reçu par e-mail.
              </h5>
            </div>
            <form @submit.prevent="loginWithCode">
              <div class="mb-3">
                <label for="code" class="form-label">Code de connexion</label>
                <input 
                  v-model="code" 
                  type="text" 
                  class="form-control" 
                  id="code" 
                  placeholder="Code de connexion" 
                />
              </div>
              <button 
                type="submit" 
                class="btn btn-secondary" 
                style="background-color: #006666;" 
                :disabled="loading"
              >
                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-else>Se connecter</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main> 
</template>

<script>
import axios from "@/axs/axios.js";
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      email: '',
      code: '',
      loading: false,
      step: 1,
    };
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  methods: {
    async requestLoginCode() {
      this.loading = true;
      try {
        await axios.post('/request-login-code', { email: this.email });
        this.step = 2;
      } catch (error) {
        console.error('Erreur lors de l\'envoi du code de connexion', error);
        Swal.fire('Erreur', 'Une erreur est survenue lors de l\'envoi du code. Veuillez réessayer.', 'error');
      }
      this.loading = false;
    },
    async loginWithCode() {
      this.loading = true;
      try {
        const response = await axios.post('/login-with-code', {
          email: this.email,
          code: this.code,
        });

        if (response.data && response.data.token && response.data.user) {
          console.log('Authentification réussie. Token:', response.data.token);
          console.log('Données utilisateur:', response.data.user);

          // Stocker le token dans sessionStorage
          sessionStorage.setItem('authToken', response.data.token);

          sessionStorage.setItem('userData', JSON.stringify(response.data.user));

          // Afficher un toast de connexion réussie
          Swal.fire({
            icon: 'success',
            title: 'Connexion réussie',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000
          });
          // Rediriger l'utilisateur en fonction du rôle
          if (response.data.user.role_id === '5f96367d-567f-479e-8e33-ef185a27d7a5') {
            this.router.push('/clients/apercu');
          } else if (response.data.user.role_id === 'dc597b6f-2083-4b7f-9d96-052a50658c73') {
            this.router.push('/admin/admin');
          } else {
            this.router.push('/'); // Redirection par défaut
          }
        } else {
          console.error('Échec de l\'authentification. Réponse de l\'API:', response.data);
          // Afficher une alerte SweetAlert en cas d'échec de connexion
          Swal.fire({
            icon: 'error',
            title: 'Échec de la connexion',
            text: response.data.message || 'E-mail ou code incorrect.',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000 // Durée du toast en millisecondes (3 secondes)
          });
        }
      } catch (error) {
        console.error('Erreur lors de la connexion avec le code', error);
        Swal.fire('Erreur', 'Connexion échouée. Veuillez vérifier votre code et réessayer.', 'error');
      }
      this.loading = false;
    },
  },
};
</script>

