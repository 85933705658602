<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import { BACK_URL } from "@/transaction_documents.js";


export default {
    components: { Header, Footer, SideBar },
    data() {
        return {

        };
    },
    mounted() {

    },

    methods: {


    },
};
</script>

<template>
    <div>
        <Header />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
              <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Recherche</h5>
                                <div class="row mb-4">
                                    <div class="col-md-3">
                                        <label for="startDate">Date de début</label>
                                        <input type="date" id="startDate"
                                            class="form-control">
                                    </div>
                                    <div class="col-md-3">
                                        <label for="endDate">Date de fin</label>
                                        <input  type="date" id="endDate"
                                            class="form-control">
                                    </div>
                                    <div class="col-md-2">
                                        <label for="searchNumber">Numéro</label>
                                        <input  type="text"
                                            class="form-control" id="searchNumber">
                                    </div>
                                    <div class="col-md-4">
                                        <label for="searchDestinataire">Expéditeur</label>
                                        <input  type="text"
                                            class="form-control" id="searchDestinataire">
                                    </div>
                                </div>

                                <button @click="exportCSV" class="btn btn-sm btn-secondary">
                                    <i class="bi bi-file-earmark-spreadsheet"></i> Export CSV
                                </button>
                                <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                                <button @click="exportToPDF" class="btn btn-sm btn-danger">
                                    <i class="bi bi-file-earmark-pdf"></i> Export PDF
                                </button>
                                <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>

                                <a class="btn btn-sm btn-primary" href="/entreprise/reseau-gsm">
                                    <i class="bi bi-diagram-2-fill"></i> Réseaux GSM
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Relevé des transactions</h5>
                                <div class="table-responsive">
                                    <table class="account-table table">
                                        <thead style="background-color: #006666;">
                                            <tr>
                                                <th scope="col" style="color: white;">N°</th>
                                                <th scope="col" style="color: white;">DATE</th>
                                                <th scope="col" style="color: white;">EXPEDITEUR</th>
                                                <th scope="col" style="color: white;">MONTANT</th>
                                                <th scope="col" style="color: white;">OPERATION</th>
                                                <th scope="col" style="color: white;">GSM</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    </div>
</template>



<style>
.file-upload-btn {
    display: inline-block;
    padding: 8px 12px;
    background-color: #006666;
    color: rgb(255, 255, 255);
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.file-upload-btn:hover {
    background-color: #006666;
}
</style>


