<template>
    <div>
      <Head/><br/><br/><br/>
      <div class="qr-container">
        <p class="payment-info">Vous pouvez payer l'entreprise directement via ce code QR :</p>
        <qrcode-vue :value="paymentLink" :size="200"></qrcode-vue>
        <div class="payment-link">
          <a :href="paymentLink" target="_blank">{{ paymentLink }}</a>
        </div>
        <p class="instructions">Scannez le code QR pour payer l'entreprise.</p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/axs/axios.js";
  import { BACK_URL } from "@/entreprise_logo.js";
  import Head from "@/components/Head.vue";
  import QrcodeVue from 'qrcode.vue';
  
  export default {
    components: {
      Head,
      QrcodeVue
    },
    data() {
      return {
        paymentLink: "https://afrikmoney.com/pay/1"
      };
    }
  };
  </script>
  
  <style>
  .qr-container {
    text-align: center;
    margin-top: 50px;
  }
  
  .payment-info {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .payment-link {
    margin-top: 20px;
    font-size: 18px;
  }
  
  .instructions {
    margin-top: 10px;
    font-size: 16px;
    color: #666;
  }
  </style>
  