<template>
    <main class="flex-shrink-0">

        <Head />
        <!-- Header -->
        <header class="py-5">
            <div class="container px-5">
                <div class="row gx-5 align-items-center">
                    <div class="col-xxl-5">
                        <div class="text-center text-xxl-start">
                            <div class="fs-3 fw-light text-dark">
                                La solution africaine de paiement et de traçabilité de vos transactions
                            </div>
                            <h1 class="display-5 fw-bolder mb-5">
                                <span class="text-gradient d-inline" style="color: #006666;">AfricaMoney</span>
                            </h1>
                            <div
                                class="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xxl-start mb-3">
                                <a class="btn btn-secondary btn-lg px-5 py-3 me-sm-3 fs-6 fw-bolder" href="/connexion"
                                    style="background-color: #006666;">Se connecter</a>
                                <a class="btn btn-outline-dark btn-lg px-8 py-3 fs-6 fw-bolder"
                                    href="/paylink-user-registration">Créer un compte</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-7">
                        <div class="d-flex justify-content-center mt-5 mt-xxl-0">
                            <div class="profile bg-gradient-primary-to-secondary">
                                <img class="profile-img" src="images/LOGOAFRICAMONEY.png" alt="Logo Africamoney" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <!-- Services -->
        <section style="background-color: #f9f9f9;">
            <div class="container">
                <div class="row text-center">
                    <div class="col-12 mb-5"><br/>
                        <h2 class="section-title" style="color: #006666;">Nos Services</h2>
                        <p class="section-subtitle text-muted">Découvrez comment Africamoney peut transformer votre
                            gestion des paiements</p>
                    </div>
                    <div class="col-lg-4 col-md-2 col-12 mb-4 d-flex align-items-stretch">
                        <div class="card rounded shadow-lg border-0">
                            <div class="card-body p-4">
                                <div class="service-icon mb-3" style="font-size: 2rem; color: #006666;">
                                    <i class="fas fa-credit-card"></i>
                                </div>
                                <h4 class="card-title mb-3" style="color: #006666;">Compte E-MoMo</h4>
                                <p class="card-text text-dark">Le compte E-MoMo est un portefeuille virtuel qui permet
                                    de gérer et de suivre en ligne les transactions Mobile-Money. Il est compatible avec
                                    tous les opérateurs Mobile-Money: MTN MOOV et CELTIIS. Créer aujourd'hui votre
                                    premier compte E-MoMo en cliquant sur ce lien "Nouveau compte E-MoMo" et générer un
                                    nouveau PayLink pour reçevoir de l'argent.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-12 mb-4 d-flex align-items-stretch">
                        <div class="card rounded shadow-lg border-0">
                            <div class="card-body p-4">
                                <div class="service-icon mb-3" style="font-size: 2rem; color: #006666;">
                                    <i class="fas fa-exchange-alt"></i>
                                </div>
                                <h4 class="card-title mb-3" style="color: #006666;">PayLink</h4>
                                <p class="card-text text-dark">Le PayLink est un moyen de paiement électronique qui
                                    permet de recevoir de l'argent sur son compte e-MoMo. Le PayLink se partage
                                    simplement entre amis, parents, collègues, partenaires d'affaires, clients,
                                    employés, débiteurs... pour gérer un projet ponctuel de collecte d'argent. Générer
                                    aujourd'hui votre PayLink pour recevoir vos dons, souscriptions, cotisations,
                                    créances, paiements de biens ou services.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-12 mb-4 d-flex align-items-stretch">
                        <div class="card rounded shadow-lg border-0">
                            <div class="card-body p-4">
                                <div class="service-icon mb-3" style="font-size: 2rem; color: #006666;">
                                    <i class="fas fa-dollar-sign"></i>
                                </div>
                                <h4 class="card-title mb-3" style="color: #006666;">Paiements innovants</h4>
                                <p class="card-text text-dark">Vous êtes une école, un centre de formation, une agence
                                    immobilière ou une boutique ? Avec la plateforme Africamoney, proposez à vos clients
                                    des modes de paiement flexibles comme le paiement par anticipation de biens et de
                                    services.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Achievements -->
        <section>
            <div class="container">
                <div class="text-center mb-5">
                    <h2 class="section-title" style="color: #006666;">Statistiques</h2>
                </div>
                <div class="row text-center">
                    <div class="col-lg-8 offset-lg-2 col-md-12 col-12">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-12 mb-4">
                                <div class="stat-card rounded shadow p-4 bg-white border-0">
                                    <div class="stat-icon mb-3" style="font-size: 2rem; color: #006666;">
                                        <i class="fas fa-building"></i>
                                    </div>
                                    <h3 class="stat-number" style="color: #006666;">+{{ statistiques.nbr_entreprise }}
                                    </h3>
                                    <p class="stat-text">PayLinks</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12 mb-4">
                                <div class="stat-card rounded shadow p-4 bg-white border-0">
                                    <div class="stat-icon mb-3" style="font-size: 2rem; color: #006666;">
                                        <i class="fas fa-exchange-alt"></i>
                                    </div>
                                    <h3 class="stat-number" style="color: #006666;">+{{ statistiques.nbr_transactions }}
                                    </h3>
                                    <p class="stat-text">Transactions</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12 mb-4">
                                <div class="stat-card rounded shadow p-4 bg-white border-0">
                                    <div class="stat-icon mb-3" style="font-size: 2rem; color: #006666;">
                                        <i class="fas fa-money-bill-wave"></i>
                                    </div>
                                    <h3 class="stat-number" style="color: #006666;">{{
                                        statistiques.total_montant_transactions }}</h3>
                                    <p class="stat-text">Paiements FCFA</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- FAQ -->
        <section>
            <div class="container">
                <div class="text-center mb-5">
                    <h2 class="section-title" style="color: #006666;">Questions Fréquemment Posées</h2>
                    <p class="section-subtitle text-muted">Vous avez des questions ? Nous avons les réponses.</p>
                </div>
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item">
                        <h4 class="accordion-header" id="faq1">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1"
                                style="color: #006666;">
                                Comment créer un PayLink ?
                            </button>
                        </h4>
                        <div id="collapse1" class="accordion-collapse collapse show" aria-labelledby="faq1"
                            data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                Pour créer un PayLink, rendez-vous sur notre page de création et suivez les instructions
                                pour configurer votre lien de paiement.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h4 class="accordion-header" id="faq2">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2"
                                style="color: #006666;">
                                Quels sont les frais de transaction ?
                            </button>
                        </h4>
                        <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="faq2"
                            data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                Les frais de transaction sont clairement indiqués lors de la création de chaque PayLink.
                                Nous offrons des tarifs compétitifs pour toutes vos transactions.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h4 class="accordion-header" id="faq3">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3"
                                style="color: #006666;">
                                Puis-je suivre mes transactions en temps réel ?
                            </button>
                        </h4>
                        <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="faq3"
                            data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                Oui, vous pouvez suivre vos transactions en temps réel via notre tableau de bord
                                intuitif.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h4 class="accordion-header" id="faq4">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4"
                                style="color: #006666;">
                                Comment contacter le support ?
                            </button>
                        </h4>
                        <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="faq4"
                            data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                Vous pouvez contacter notre support via le formulaire de contact sur notre site ou par
                                email à support@africamoney.com.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>   <br /><br />

    <footer class="py-4 mt-auto" style="background-color: #006666; color: white;">
    <div class="container">
        <div class="row align-items-center justify-content-center justify-content-sm-between text-center text-sm-start">
            <div class="col-auto">
                <div class="small m-0" v-text="'© Africamoney ' + new Date().getFullYear()"></div>
            </div>
            <div class="col-auto">
                <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Confidentialité</a>
                <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Terms et conditions</a>
            </div>
        </div>
    </div>
</footer>

</template>


<script>
import Head from "@/components/Head.vue";
import axios from "@/axs/axios.js";

export default {
    components: { Head },
    data() {
        return {
            statistiques: {}
        };
    },
    mounted() {
        this.fetchStatistiques();
    },
    methods: {
        fetchStatistiques() {
            axios.get('/statistiques/transactions')
                .then(response => {
                    this.statistiques = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
}
</script>

<style>
.section-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.section-subtitle {
    font-size: 1.1rem;
    color: #666;
}

.card {
    transition: transform 0.3s ease-in-out;
}

.card:hover {
    transform: translateY(-10px);
}

.service-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;
    margin: 0 auto;
}
</style>