<template>
    <nav id="sidebarMenu" class="col-md-2 col-lg-2 d-md-block sidebar collapse">
        <div class="position-sticky py-4 px-3 sidebar-sticky">
            <ul class="nav flex-column h-100">

                <!-- ACCEUIL -->
                <li class="nav-item">
                    <a v-if="userRole === '5f96367d-567f-479e-8e33-ef185a27d7a5'" class="nav-link" href="/clients/apercu">
                        <i class="bi-house-fill me-2"></i>
                        <b> ACCEUIL </b>
                    </a>

                    <a v-if="userRole === 'dc597b6f-2083-4b7f-9d96-052a50658c73'" class="nav-link" href="/admin/admin">
                        <i class="bi-house-fill me-2"></i>
                        <b> ACCEUIL</b>
                    </a>

                    <a v-if="userRole === 'dc597b6f-2083-4b7f-9d96-052a50658c73'" class="nav-link" href="/admin/mes-entites">
                        <i class="bi-people me-2"></i>
                        <b> MES ENTITES </b>
                    </a>
                </li>

                <a v-if="userRole === '5f96367d-567f-479e-8e33-ef185a27d7a5'" class="nav-link" href="/clients/client">
                    <i class="bi bi-people-fill me-2"></i><b>MES PAYLINKS</b>
                </a>

                <li class="nav-item">
                    <a v-if="allowTransactions" class="nav-link" :href="transactionLink">
                        <i class="bi-wallet me-2"></i>
                        <b> MES TRANSACTIONS</b>
                    </a>
                </li>

                <a v-if="userRole === '5f96367d-567f-479e-8e33-ef185a27d7a5'" class="nav-link" href="/clients/parametre">
                    <i class="bi bi-gear me-2"></i><b>PARAMETRES</b>
                </a>

                <li class="nav-item">
                    <a class="nav-link" @click="logoutUser">
                        <i class="bi-box-arrow-left me-2"></i>
                        <b> DECONNEXION</b>
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import axios from "@/axs/axios.js";

export default {
    data() {
        return {
            userRole: null,
            allowTransactions: false,
            loading: false
        };
    },

    computed: {
        transactionLink() {
            if (this.userRole === '5f96367d-567f-479e-8e33-ef185a27d7a5') {
                return '/clients/transaction';
            } else {
                return '#';
            }
        }
    },

    methods: {
        async logoutUser() {
            try {
                this.loading = true;
                const response = await axios.post('/logout', null, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
                    },
                });

                if (response.status === 200) {
                    sessionStorage.removeItem('authToken');
                    this.$router.push('/');
                } else {
                    console.error('Échec de la déconnexion. Réponse de l\'API:', response.data);
                }
            } catch (error) {
                console.error('Erreur lors de la requête de déconnexion:', error);
            } finally {
                this.loading = false;
            }
        },

        getUserRole() {
            const sessionData = JSON.parse(sessionStorage.getItem('userData'));
            if (sessionData && sessionData.role_id) {
                this.userRole = sessionData.role_id;
                this.allowTransactions = this.userRole === '5f96367d-567f-479e-8e33-ef185a27d7a5';
            } else {
                this.userRole = null;
                this.allowTransactions = false;
            }
        }
    },
    mounted() {
        this.getUserRole();
    }
}
</script>

<style>
#sidebarMenu .nav-link.active,
#sidebarMenu .nav-link:hover {
    color: #fff;
}
</style>
