<template>
  <div class="container">
    <header class="navbar sticky-top flex-md-nowrap bg-dark">
      <div class="col-md-3 col-lg-3 me-0 px-3 fs-6">
        <a class="navbar-brand" href="/">
          <h6 class="text-white text-center">Détails du PayLink</h6>
        </a>
      </div>
    </header>


    <main class="main">
      <Head />

      <div class="col-lg-12 col-12">
        <div
          class="custom-block custom-block-profile-front custom-block-profile text-center bg-white d-flex flex-column align-items-center justify-content-center"
          v-if="payLink">
          
          <!-- Affichage de l'image -->
          <div class="image-container">
            <img :src="BACK_URL + payLink.image" alt="Image du PayLink" style="max-width: 100%; height: auto;" />
          </div>

          <!-- Affichage du nom et autres détails -->

          <div class="text-center"> <br />
            <h6><i>“ {{ payLink.description }} ”</i></h6>
          </div>

          <button class="btn btn-success mt-4" @click="showSupportModal"
            style="background-color: #006666; border-color: #006666; font-size: 1.25rem; padding: 10px 20px;">
            Me soutenir <i class="bi bi-gift me-2"></i>
          </button>
        </div>
      </div>
    </main>

    <!-- Modal -->
    <div class="modal fade" id="supportModal" tabindex="-1" aria-labelledby="supportModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header" style="background-color: #006666">
            <h5 class="modal-title" id="supportModalLabel" style="color: white">
              NOUVEAU PAIEMENT
            </h5>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item">
                <a class="nav-link" :class="{
                  active: selectedTab === 'MTN',
                  '': selectedTab === 'MTN',
                }" @click.prevent="handleTabClick('MTN')" href="#">
                  <img src="/images/logomtn.svg" alt="MTN" style="height: 50px; margin-right: 28px" />
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{
                  active: selectedTab === 'MOOV',
                  '': selectedTab === 'MOOV',
                }" @click.prevent="handleTabClick('MOOV')" href="#">
                  <img src="/images/logomoov.png" alt="MOOV" style="height: 50px; margin-right: 28px" />
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{
                  active: selectedTab === 'CELTIIS',
                  '': selectedTab === 'CELTIIS',
                }" @click.prevent="handleTabClick('CELTIIS')" href="#">
                  <img src="/images/celtislogo.png" alt="CELTIIS" style="height: 50px; margin-right: 28px" />
                </a>
              </li>
            </ul>
            <div v-if="isUnavailable" class="alert alert-info mt-3" role="alert">
              Non disponible
            </div>
            <div v-else>
              <!-- Formulaire pour entrer les détails de paiement -->
              <div class="mb-3">
                <label for="nom" class="form-label">Nom</label>
                <input type="text" class="form-control" id="nom" v-model="nom" placeholder="Entrez votre nom"
                  required />
              </div>
              <div class="mb-3">
                <label for="prenom" class="form-label">Prénom</label>
                <input type="text" class="form-control" id="prenom" v-model="prenom" placeholder="Entrez votre prénom"
                  required />
              </div>
              <div class="mb-3">
                <label for="amount" class="form-label">Montant</label>
                <input type="number" class="form-control" id="amount" v-model="amount" min="1" />
              </div>
              <div class="mb-3">
                <label for="paymentNumber" class="form-label">Numéro de paiement</label>
                <input type="text" v-model="paymentNumber" @blur="validateAndPrefixNumber" class="form-control"
                  id="paymentNumber" placeholder="Ex:00000000" required />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="processPayment"
              style="background-color: #006666; border-color: #006666;"> Envoyer
            </button>
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
              Quitter
            </button>
          </div>
        </div>
      </div>
    </div>

    <br /><br /><br />

    <footer class="py-4 mt-auto" style="background-color: #006666; color: white;">
      <div class="container">
        <div class="row align-items-center justify-content-center justify-content-sm-between text-center text-sm-start">
          <div class="col-auto">
            <div class="small m-0" v-text="'© Africamoney ' + new Date().getFullYear()"></div>
          </div>
          <div class="col-auto">
            <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Confidentialité</a>
            <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Terms et conditions</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "@/axs/axios.js";
import Head from "@/components/Head.vue";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { BACK_URL } from "@/entreprise_logo.js";
import Swal from "sweetalert2";

export default {
  components: { Head },
  setup() {
    const route = useRoute();
    const payLink = ref(null);  // Stocke les détails du payLink
    const payLinkId = ref(null);  // Stocke l'ID du payLink

    // Fonction pour récupérer les détails du payLink
    const fetchPayLinkDetails = async (id) => {
      try {
        const response = await axios.get(`/show-paylinks/${id}`);
        payLink.value = response.data;  // Assurez-vous que la réponse contient les détails sous 'data'
        payLinkId.value = payLink.value.id;  // Assurez-vous que l'ID est bien récupéré
      } catch (error) {
        console.error("Erreur lors de la récupération des détails du payLink:", error);
      }
    };

    // Récupère les détails du payLink lorsque le composant est monté
    onMounted(() => {
      if (route.params.id) {
        fetchPayLinkDetails(route.params.id);
      }
    });

    return {
      payLink,
      payLinkId,
      BACK_URL,
    };
  },
};
</script>
