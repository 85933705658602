<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Chart from "chart.js/auto";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { BACK_URL } from "@/entreprise_logo.js";
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  components: { Header, Footer, SideBar, QrcodeStream },
  data() {
    return {
      // Define your data here if needed
    };
  },
  methods: {
    // Define your methods here if needed
  },
  mounted() {
    // Define any actions needed when the component is mounted
  },
};
</script>

<template>
  <Header />
  <div class="container-fluid">
    <div class="row">
      <SideBar />
      <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
        <div class="title-group mb-3">
          <h5 class="h5 mb-0 text-center">
            Bonjour, bienvenue sur Africamoney !
          </h5>
        </div>

        <div class="row my-4">
          <div class="col-lg-7 col-12">
            <div class="custom-block custom-block-balance">
              <small>Solde</small>
              <h2 class="mt-2 mb-3">
                50000 FCFA
              </h2>
              <div class="d-flex">
                <div>
                  <small>Date d'inscription</small>
                  <p>
                    Salut salut
                  </p>
                </div>
              </div>
            </div>

            <div class="custom-block bg-white">
              <h5 class="mb-4">Statistiques</h5>
              <canvas id="pie-chart"></canvas>
            </div>
          </div>

          <div class="col-lg-5 col-12">
            <div class="custom-block custom-block-profile-front custom-block-profile text-center bg-white">
              <div class="custom-block-profile-image-wrap mb-4">
                <img class="custom-block-profile-image img-fluid"
                  alt="africamoney" />
              </div>
              <div class="text-start">
                <div>
                  <h5>
                    <span class="badge" style="background-color: #006666">
                      <strong style="color: #ffffff">COMPTE</strong>
                    </span><br />
                    <a class="ms-2" style="font-size: 1rem; color: #000000">
                      <!-- Account information here -->
                    </a>
                  </h5>
                </div>
                <h5>
                  <span class="badge" style="background-color: #006666">
                    <strong style="color: #ffffff">E-mail</strong>
                  </span><br />
                  <a class="ms-2" style="font-size: 1rem; text-transform: lowercase; color: #000000">
                    <!-- Email here -->
                  </a>
                </h5>
                <h5>
                  <span class="badge" style="background-color: #006666">
                    <strong style="color: #ffffff">Téléphone</strong>
                  </span><br />
                  <a class="ms-2" style="font-size: 1rem; text-transform: lowercase; color: #000000">
                    <!-- Phone number here -->
                  </a>
                </h5>
                <h5>
                  <span class="badge" style="background-color: #006666">
                    <strong style="color: #ffffff">Adresse</strong>
                  </span><br />
                  <a class="ms-2" style="font-size: 1rem; text-transform: lowercase; color: #000000">
                    <!-- Address here -->
                  </a>
                </h5>
              </div>
            </div>

            <div class="custom-block custom-block-bottom d-flex flex-wrap">
  <div class="custom-block-bottom-item">
    <a href="#" class="d-flex flex-column">
      <i class="custom-block-icon bi-link-45deg"></i>
      <small>Mes Paylinks</small>
    </a>
  </div>

  <div class="custom-block-bottom-item">
    <a href="#" class="d-flex flex-column">
      <i class="custom-block-icon bi-credit-card"></i>
      <small>Mes transactions</small>
    </a>
  </div>

  <div class="custom-block-bottom-item">
    <a href="#" class="d-flex flex-column">
      <i class="custom-block-icon bi-plus-circle"></i>
      <small>Ajouter un Paylinks</small>
    </a>
  </div>
</div>

<div class="custom-block custom-block-bottom d-flex flex-wrap">
  <div class="custom-block-bottom-item">
    <a href="#" class="d-flex flex-column">
      <i class="custom-block-icon bi-book"></i>
      <small>Guide d'utilisation</small>
    </a>
  </div>
</div>

          </div>
        </div>

        <Footer />
      </main>
    </div>
  </div>
</template>

<style scoped>
/* Add your scoped styles here */
.custom-block {
  margin-bottom: 1rem;
}
.custom-block-balance {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 0.5rem;
}
.custom-block-profile {
  padding: 1rem;
  border-radius: 0.5rem;
}
.custom-block-bottom-item i {
  font-size: 1.5rem;
}
</style>
