<template>
    <header class="navbar sticky-top flex-md-nowrap">
        <div class="col-md-3 col-lg-3 me-0 px-3 fs-6">
            <a class="navbar-brand" href="/">
                <h3 class="text-white"> AfricaMoney </h3>
            </a>
        </div>

        <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <!--         <div class="navbar-nav me-lg-2">
            <div class="nav-item text-nowrap d-flex align-items-center">
                <div class="dropdown px-3">
                    <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <img src="/../../images/LOGOAFRICAMONEY.png" class="profile-image img-fluid me-3" alt="">
                    </a>
                    <ul class="dropdown-menu bg-white shadow">
                        <li>
                            <div class="dropdown-menu-profile-thumb d-flex">
                                <img src="/../../images/LOGOAFRICAMONEY.png" class="profile-image img-fluid me-3" alt="">
                                <div class="d-flex flex-column">
                                    
                                    <a v-if="user" :href="'mailto:' + user.email">{{ user.email }}</a>
                                </div>
                            </div>
                        </li>

                        <li>
                            <router-link v-if="user" :to="'/entreprise/profile/' + user.id" class="dropdown-item">
                                <i class="bi-person me-2"></i>
                                Profil
                            </router-link>
                        </li>
                        <li class="border-top mt-3 pt-2 mx-4">
                            <a v-if="user" class="dropdown-item ms-0 me-0" @click="logoutUser">
                                <i class="bi-box-arrow-left me-2"></i>
                                Déconnexion
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>   -->
    </header>
</template>

<script>
import axios from "@/axs/axios.js";

export default {
    data() {
        return {
            user: null, // Initialisez user à null plutôt qu'à une chaîne vide
        };
    },
    mounted() {
        this.fetchUserProfile();
    },
    methods: {
        async fetchUserProfile() {
            try {
                const response = await axios.get('/profile', {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
                    },
                });

                if (response.data && response.data.user) { // Correction ici
                    this.user = response.data.user; // Correction ici
                } else {
                    console.error('Échec de la récupération du profil. Réponse de l\'API:', response.data);
                }
            } catch (error) {
                console.error('Erreur lors de la requête de récupération du profil:', error);
            }
        },

        async logoutUser() {
            try {
                this.loading = true;

                const response = await axios.post('/logout', null, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
                    },
                });

                if (response.status === 200) {
                    console.log('Déconnexion réussie.');
                    sessionStorage.removeItem('authToken');
                    this.$router.push('/').then(() => {
                        // Rafraîchir la page après la redirection
                        window.location.reload();
                    });
                } else {
                    console.error('Échec de la déconnexion. Réponse de l\'API:', response.data);
                }
            } catch (error) {
                console.error('Erreur lors de la requête de déconnexion:', error);
            } finally {
                this.loading = false;
            }
        },

    }
}
</script>

<style></style>
