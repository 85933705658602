<template>

    <Head />
    <br /><br />
    <div class="container">
        <div class="text-center">
            <h6><i>Je m'engage !</i></h6>
        </div>

        <!-- Alerte explicative -->
        <div class="alert alert-info d-flex align-items-center" role="alert">
            <i class="fas fa-info-circle fa-2x me-3"></i>
            <div>Notre engagement c’est d’être toujours à vos côtés et vous rappelez vos échéances de paiement. Recevez
                en avance, un message ou un appel pour vous souvenir des dates importantes et garder de bonnes relations
                avec vos partenaires. Merci de nous faire confiance !
            </div>
        </div>

        <form @submit.prevent="submitForm">
            <!-- Nom complet -->
            <div class="form-group">
                <label for="fullName">Nom complet :</label>
                <input type="text" v-model="fullName" id="fullName" required placeholder="Entrez votre nom complet" />
            </div>

            <div class="form-group">
                <label for="Email">E-mail :</label>
                <input type="text" v-model="Email" id="Email" required placeholder="Entrez e-mail" />
            </div>

            <div class="form-group">
                <label for="institution">Sélectionnez l'entité :</label>
                <select v-model="selectedInstitution" id="institution" required>
                    <option disabled value="">Veuillez sélectionner</option>
                    <option v-for="institution in institutions" :key="institution.id" :value="institution.id">
                        {{ institution.nom }} ({{ institution.adresse
                        }})<!-- Changez 'name' en 'nom' pour correspondre à la clé dans votre API -->
                    </option>
                </select>
            </div>

            <!-- Motif de l'engagement -->
            <div class="form-group">
                <label for="engagementReason">Motif de l'engagement :</label>
                <select v-model="engagementReason" id="engagementReason" required>
                    <option value="" disabled selected>Choisissez un motif</option>
                    <option value="Frais de scolarité">Frais de scolarité</option>
                    <option value="Prêt">Prêt</option>
                    <option value="Loyer">Loyer</option>
                    <option value="Achat à crédit">Achat à crédit</option>
                </select>
            </div>

            <div class="form-group">
                <label for="engagementPerson">Personne lié à l'engagement :</label>
                <input type="text" v-model="engagementPerson" id="engagementPerson" required />
            </div>

            <!-- Montant à payer -->
            <div class="form-group">
                <label for="amount">Montant à payer :</label>
                <input type="number" v-model="engagement_amount" id="amount" required
                    placeholder="Entrez le montant en FCFA" />
            </div>

            <!-- Dates de rappel de paiement -->
            <div class="form-group">
                <label for="paymentDates">Dates de rappel de paiement :</label>
                <div v-for="(date, index) in paymentDates" :key="index" class="payment-date">
                    <input type="date" v-model="date.value" required />
                    <button type="button" class="btn btn-sm btn-danger" @click="removeDate(index)">
                        <i class="fas fa-trash-alt remove-icon"></i>
                    </button>
                    <button type="button" class="btn btn-sm btn-primary" @click="addDate">
                        <i class="fas fa-plus-circle add-icon"></i>
                    </button>
                </div>
            </div>

            <!-- Numéro de téléphone -->
            <div class="form-group">
                <label for="phoneNumber">Numéro de téléphone :</label>
                <input type="tel" v-model="phoneNumber" id="phoneNumber" required
                    placeholder="Entrez votre numéro de téléphone" />
            </div>

            <!-- Numéro WhatsApp -->
            <div class="form-group">
                <label for="whatsappNumber">Numéro WhatsApp :</label>
                <input type="tel" v-model="whatsappNumber" id="whatsappNumber" required
                    placeholder="Entrez votre numéro WhatsApp" />
            </div>

            <!-- Mode de rappel préféré -->
            <div class="form-group">
                <label>Mode de rappel préféré :</label>
                <div>
                    <label><input type="checkbox" value="whatsapp" v-model="reminderModes" /> WhatsApp</label>
                    <span>&nbsp;</span><span>&nbsp;</span>
                    <label><input type="checkbox" value="sms" v-model="reminderModes" /> SMS</label>
                    <span>&nbsp;</span><span>&nbsp;</span>
                    <label><input type="checkbox" value="appel" v-model="reminderModes" /> Appel</label>
                </div>
            </div>

            <!-- Bouton de soumission -->
            <button class="btn btn-success mt-4" @click="showSupportModal">Payer et s'engager</button>
        </form><br /><br />
    </div>
    <!-- Modal -->
    <div class="modal fade" id="supportModal" tabindex="-1" aria-labelledby="supportModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header" style="background-color: #006666">
                    <h5 class="modal-title" id="supportModalLabel" style="color: white">
                        NOUVEAU PAIEMENT
                    </h5>
                </div>
                <div class="modal-body">
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link" :class="{
                                active: selectedTab === 'MTN',
                                '': selectedTab === 'MTN',
                            }" @click.prevent="handleTabClick('MTN')" href="#">
                                <img src="/images/logomtn.svg" alt="MTN" style="height: 50px; margin-right: 28px" />

                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{
                                active: selectedTab === 'MOOV',
                                '': selectedTab === 'MOOV',
                            }" @click.prevent="handleTabClick('MOOV')" href="#">
                                <img src="/images/logomoov.png" alt="MOOV" style="height: 50px; margin-right: 28px" />

                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{
                                active: selectedTab === 'CELTIIS',
                                '': selectedTab === 'CELTIIS',
                            }" @click.prevent="handleTabClick('CELTIIS')" href="#">
                                <img src="/images/celtislogo.png" alt="CELTIIS"
                                    style="height: 50px; margin-right: 28px" />
                            </a>
                        </li>
                    </ul>
                    <div v-if="isUnavailable" class="alert alert-info mt-3" role="alert">
                        Non disponible
                    </div>
                    <div v-else>
                        <!-- Formulaire pour entrer les détails de paiement -->
                        <div class="mb-3">
                            <label for="nom" class="form-label">Nom</label>
                            <input type="text" class="form-control" id="nom" v-model="nom"
                                placeholder="Entrez votre nom" required />
                        </div>
                        <div class="mb-3">
                            <label for="prenom" class="form-label">Prénom</label>
                            <input type="text" class="form-control" id="prenom" v-model="prenom"
                                placeholder="Entrez votre prénom" required />
                        </div>
                        <div class="mb-3">
                            <label for="amount" class="form-label">Montant</label>
                            <input type="number" class="form-control" id="amount" v-model="amount" min="1" readonly />
                        </div>
                        <div class="mb-3">
                            <label for="paymentNumber" class="form-label">Numéro de paiement</label>
                            <input type="text" v-model="paymentNumber" @blur="validateAndPrefixNumber"
                                class="form-control" id="paymentNumber" placeholder="Ex:00000000" required />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="processPayment"
                        style="background-color: #006666; border-color: #006666;"> Envoyer
                    </button>
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                        Quitter
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/axs/axios.js";
import Head from "@/components/Head.vue";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";

export default {
    components: { Head },
    setup() {
        const route = useRoute();
        const amount = ref(1); // Montant par défaut à 1
        const paymentNumber = ref("");
        const currency = ref("XOF");
        const externalId = ref("12345678");
        const payer = ref({
            partyIdType: "MSISDN",
            partyId: "",
        });

        const selectedTab = ref("MTN");
        const nom = ref("");
        const prenom = ref("");
        const isCheckingStatus = ref(false);
        const isProcessingPayment = ref(false);
        const paymentReferenceId = ref(null);
        const isUnavailable = ref(false);

        const selectedInstitution = ref('');
        const phoneNumber = ref('');
        const whatsappNumber = ref('');
        const engagement_amount = ref('');
        const fullName = ref('');
        const Email = ref('');
        const engagementReason = ref('');
        const engagementPerson = ref('');
        const paymentDates = ref([{ value: '' }]);
        const reminderModes = ref(['whatsapp']);  // WhatsApp par défaut sélectionné

        const institutions = ref([]);
        const file = ref(null);

        const fetchInstitutions = async () => {
            try {
                const response = await axios.get("/get-entites");
                if (response.data.success) {
                    institutions.value = response.data.data;
                } else {
                    console.error("Erreur lors de la récupération des institutions");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des données :", error);
            }
        };

        onMounted(() => {
            fetchInstitutions();
        });

        const validateAndPrefixNumber = () => {
            if (paymentNumber.value && !paymentNumber.value.startsWith("229")) {
                paymentNumber.value = `229${paymentNumber.value}`;
            }
        };

        const showSupportModal = () => {
            const supportModal = new bootstrap.Modal(
                document.getElementById("supportModal")
            );
            supportModal.show();
        };

        const handleTabClick = (tab) => {
            if (tab === 'MOOV' || tab === 'CELTIIS') {
                isUnavailable.value = true;
            } else {
                isUnavailable.value = false;
            }
            selectedTab.value = tab;
        };

        const processPayment = async () => {
            if (amount.value <= 0 || !paymentNumber.value || !selectedInstitution.value) {
                Swal.fire({
                    icon: "warning",
                    title: "Champs invalides",
                    text: "Veuillez entrer un montant positif, un numéro de paiement, et sélectionner une institution.",
                });
                return;
            }

            payer.value.partyId = paymentNumber.value;

            try {
                const data = {
                    amount: amount.value,
                    paymentNumber: paymentNumber.value,
                    currency: currency.value,
                    externalId: externalId.value,
                    payer: payer.value,
                    nomdestinataire: `${nom.value} ${prenom.value}`,
                    mode: 1,
                    numero: paymentNumber.value,
                    operateur: selectedTab.value, // Utilisez l'onglet sélectionné comme opérateur
                    entite_id: selectedInstitution.value // Ajout de l'entite_id ici
                };

                console.log(data);
                const response = await axios.post("/process-payment-engagement", data);

                if (response.status === 200 || response.status === 202) {
                    paymentReferenceId.value = response.data.paiement.referenceId;
                    console.log(response.data.paiement.referenceId);
                    startCheckingStatus(paymentReferenceId.value);

                    Swal.close();
                    Swal.fire({
                        title: "Paiement en cours",
                        text: `Paiement de ${amount.value} XOF initié. En attente du paiement sur votre téléphone...`,
                        icon: "info",
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });
                }
            } catch (error) {
                console.error("Erreur lors du traitement du paiement :", error);
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    text: "Une erreur s'est produite lors du traitement du paiement. Veuillez réessayer.",
                });
            }
        };

        const startCheckingStatus = (referenceId) => {
            isCheckingStatus.value = true;
            const checkStatusInterval = setInterval(async () => {
                try {
                    const response = await axios.get(`/check-payment-status-engagement/${referenceId}`);
                    if (response.status === 200) {
                        const status = response.data.status;
                        if (status === 'SUCCESSFUL') {
                            clearInterval(checkStatusInterval);
                            isCheckingStatus.value = false;
                            await submitForm();  // Appel à submitForm uniquement après confirmation du paiement
                        }
                    }
                } catch (error) {
                    console.error("Erreur lors de la vérification du statut :", error);
                }
            }, 5000);
        };

        const submitForm = async () => {
    if (paymentReferenceId.value) {
        const formData = new FormData();
        formData.append("fullName", fullName.value);
        formData.append("Email", Email.value);
        formData.append("selectedInstitution", selectedInstitution.value);
        formData.append("engagementReason", engagementReason.value);
        formData.append("engagementPerson", engagementPerson.value);
        formData.append("engagement_amount", engagement_amount.value);
        formData.append("phoneNumber", phoneNumber.value);
        formData.append("whatsappNumber", whatsappNumber.value);
        reminderModes.value.forEach(mode => {
    formData.append('reminderModes[]', mode);
});
        paymentDates.value.forEach((date, index) => {
            formData.append(`paymentDates[${index}]`, date.value);
        });

        try {
            const response = await axios.post('/e-engagements', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200 || response.status === 201) {
                Swal.fire({
                    icon: "success",
                    title: "Succès",
                    text: "Votre engagement a été enregistré avec succès.",
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Erreur",
                    text: "Une erreur s'est produite lors de l'enregistrement de l'engagement. Veuillez réessayer.",
                });
            }
        } catch (error) {
            console.error("Erreur lors de l'envoi des données :", error);
            Swal.fire({
                icon: "error",
                title: "Erreur",
                text: "Une erreur s'est produite lors de l'envoi des données. Veuillez réessayer.",
            });
        }
    }
};


        const addDate = () => {
            paymentDates.value.push({ value: '' });
        };

        const removeDate = (index) => {
            if (paymentDates.value.length > 1) {
                paymentDates.value.splice(index, 1);
            }
        };

        return {
            amount,
            paymentNumber,
            currency,
            externalId,
            payer,
            selectedTab,
            nom,
            prenom,
            isCheckingStatus,
            isProcessingPayment,
            paymentReferenceId,
            isUnavailable,
            selectedInstitution,
            phoneNumber,
            whatsappNumber,
            engagement_amount,
            fullName,
            Email,
            engagementReason,
            engagementPerson,
            paymentDates,
            reminderModes,
            institutions,
            validateAndPrefixNumber,
            showSupportModal,
            handleTabClick,
            processPayment,
            submitForm,
            addDate,
            removeDate,
        };
    },
};
</script>



<style scoped>
.container {
    max-width: 600px;
    margin: 0 auto;
}

form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 1rem;
}

.payment-date {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.payment-date button {
    margin-left: 10px;
    padding: 0.3rem 0.6rem;
    font-size: 0.9rem;
    background-color: #cc0000;
    color: white;
    border: none;
    cursor: pointer;
}

label {
    margin-bottom: 0.5rem;
}

input,
select,
textarea {
    padding: 0.5rem;
    font-size: 1rem;
    width: 100%;
}

button {
    padding: 0.2rem;
    font-size: 1.2rem;
    background-color: #006666;
    color: white;
    border: none;
    cursor: pointer;
}

.remove-icon,
.add-icon {
    font-size: 0.875rem;
    /* Ajustez cette valeur selon la taille souhaitée */
    margin-right: 5px;
}
</style>
