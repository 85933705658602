<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Chart from "chart.js/auto";

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            totalSuccessfulAmount: 0,
            entitesCount: 0,
            paylinksCount: 0,
            engagementsCount: 0,
            clientsCount: 0,
            totalsByOperator: {},
        };
    },
    methods: {
        fetchCounts() {
            axios.get("/count-records")
                .then(response => {
                    this.entitesCount = response.data.entites_count;
                    this.paylinksCount = response.data.paylinks_count;
                    this.engagementsCount = response.data.engagements_count;
                    this.clientsCount = response.data.clients_count;
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération des données :", error);
                });
        },
        fetchTotalSuccessfulAmount() {
            axios.get("/total-successful-amount")
                .then(response => {
                    this.totalSuccessfulAmount = response.data.total_successful_amount;
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération du montant total:", error);
                });
        },
        fetchTotalsByOperator() {
            axios.get("/total-amount-by-operator")
                .then(response => {
                    this.totalsByOperator = response.data.reduce((acc, curr) => {
                        acc[curr.operateur] = curr.total;
                        return acc;
                    }, {});
                    this.renderPieChart();
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération des totaux par opérateur:", error);
                });
        },
        renderPieChart() {
            const ctx = document.getElementById('pie-chart').getContext('2d');
            new Chart(ctx, {
                type: 'pie',
                data: {
                    labels: Object.keys(this.totalsByOperator),
                    datasets: [{
                        data: Object.values(this.totalsByOperator),
                        backgroundColor: ['#ffd014', '#36A2EB', '#FFCE56'],
                    }]
                },
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                    }
                }
            });
        }
    },
    mounted() {
        this.fetchCounts();
        this.fetchTotalSuccessfulAmount();
        this.fetchTotalsByOperator();
    },
};

</script>


<template>
    <Header />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
            <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                <div class="title-group mb-3">
                    <h5 class="h5 mb-0 text-center"> NERO-TECHNOLOGIES</h5>
                </div>

                <div class="row my-4">
                    <div class="col-lg-7 col-12">
                        <div class="custom-block custom-block-balance">
                            <small>TRANSACTIONS</small>
                            <h2 class="mt-2 mb-3"> {{ totalSuccessfulAmount }} FCFA</h2>
                        </div>
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">Statistiques</h5>
                            <canvas id="pie-chart"></canvas>
                        </div>
                    </div>

                    <div class="col-lg-5 col-12">
                        <div class="custom-block custom-block-profile-front custom-block-profile text-center bg-white">
                            <div class="custom-block-profile-image-wrap mb-4">
                                <h5>Comptes:</h5>
                            </div>
                            <p class="text-start">
                                <strong>Entités:</strong>
                                <a>{{ entitesCount }}</a>
                            </p>

                            <p class="text-start">
                                <strong>Engagements:</strong>
                                <a>{{ engagementsCount }}</a>
                            </p>

                            <p class="text-start">
                                <strong>PayLinks:</strong>
                                <a>{{ paylinksCount }}</a>
                            </p>

                            <p class="text-start">
                                <strong>Clients:</strong>
                                <a>{{ clientsCount }}</a>
                            </p>
                        </div>

                        <div class="custom-block custom-block-bottom d-flex flex-wrap">
                            <div class="custom-block-bottom-item">
                                <a href="#" class="d-flex flex-column" @click="generatePDF">
                                    <i class="custom-block-icon bi-book"></i>
                                    <small>Guide d'utilisation</small>
                                </a>
                            </div>
                        </div>

                        <div class="custom-block custom-block-profile-front custom-block-profile text-center bg-white">
                            <div class="custom-block-profile-image-wrap mb-4">
                                <h5>Engagements</h5>
                            </div>
                            <canvas id="line-chart"></canvas>
                        </div>

                        <div class="custom-block custom-block-profile-front custom-block-profile text-center bg-white">
                            <div class="custom-block-profile-image-wrap mb-4">
                                <h5>Clients</h5>
                            </div>
                            <canvas id="client-line-chart"></canvas>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    </div>
</template>

