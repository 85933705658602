<template>
  <Header />

  <div class="container-fluid">
    <div class="row">
      <SideBar />
    <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
        <div class="title-group mb-3">
          <h1 class="h2 mb-0">Paramètres</h1>
        </div>

        <div class="row my-4">
          <div class="col-lg-7 col-12">
            <div class="custom-block bg-white">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="profile-tab" data-bs-toggle="tab"
                    data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane"
                    aria-selected="true">
                    Profil
                  </button>
                </li>

                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="password-tab" data-bs-toggle="tab" data-bs-target="#password-tab-pane"
                    type="button" role="tab" aria-controls="password-tab-pane" aria-selected="false">
                    Mot de passe
                  </button>
                </li>

                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="bonus-tab" data-bs-toggle="tab" data-bs-target="#bonus-tab-pane"
                    type="button" role="tab" aria-controls="bonus-tab-pane" aria-selected="false">
                    Bonus client
                  </button>
                </li>
              </ul>

              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="profile-tab-pane" role="tabpanel"
                  aria-labelledby="profile-tab" tabindex="0">
                  <h6 class="mb-4"> Modifiez vos informations</h6>

                  <form class="custom-form profile-form" @submit.prevent="updateEnterprise" role="form">
                    <input class="form-control" type="text" v-model="entreprise" placeholder="Entreprise" />
                    <input class="form-control" type="tel" v-model="telephone" placeholder="Télephone" />
                    <input class="form-control" type="text" v-model="adresse" placeholder="Adresse" />
                    <input class="form-control" type="file" accept="image/*" @change="handleLogoChange" />
                    <div class="d-flex">
                      <button type="submit" class="form-control ms-2">Mettre à jour</button>
                    </div>
                  </form>
                </div>

                <div class="tab-pane fade" id="password-tab-pane" role="tabpanel" aria-labelledby="password-tab"
                  tabindex="0">
                  <h6 class="mb-4">Modifier le mot de passe</h6>
                  <form class="custom-form profile-form" @submit.prevent="updatePassword" role="form">
                    <input class="form-control" type="password" v-model="oldPassword"
                      placeholder="Ancien mot de passe" />
                    <input class="form-control" type="password" v-model="newPassword"
                      placeholder="Nouveau mot de passe" />

                    <button type="submit" class="form-control ms-2">Mettre à jour le mot de
                      passe</button>
                  </form>
                </div>

                <div class="tab-pane fade" id="bonus-tab-pane" role="tabpanel" aria-labelledby="bonus-tab" tabindex="0">
                  <h6 class="mb-4">Bon de réduction pour vos clients</h6>
                  <!-- Date de début -->
                  <div class="mb-3">
                    <label for="start_date" class="form-label">Date de début</label>
                    <input type="date" class="form-control" id="start_date" v-model="startDate">
                  </div>
                  <!-- Date de fin -->
                  <div class="mb-3">
                    <label for="end_date" class="form-label">Date de fin</label>
                    <input type="date" class="form-control" id="end_date" v-model="endDate">
                  </div>
                  <div class="progress-container">
                    <input type="range" class="form-range" min="0" max="100" v-model="bonusProgress" step="1"
                      @change="updateBonusProgress">
                  </div>
                  <p class="progress-label">{{ bonusProgress }} % (Réduction)</p>
                  <br />
                  <h6 class="mb-4">Transaction</h6>
                  <div class="progress-container">
                    <input type="range" class="form-range" min="0" max="1000000" v-model="transactionProgress"
                      step="1000" @change="updateTransactionProgress">
                  </div>
                  <p class="progress-label">{{ transactionProgress }} FCFA (Total)</p>
                  <br />
                  <div class="d-flex">
                    <button type="submit" class="form-control ms-2 btn btn-success"
                      @click="applyChanges">Enregistrer</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-5 col-12">
            <div class="custom-block custom-block-contact">
              <h6 class="mb-4">
                Vous ne trouvez toujours pas ce que vous cherchez ?
              </h6>
              <p>
                <strong>Appelez-nous :</strong>
                <a href="tel: 97000000" class="ms-2">
                  (+229) 97000000
                </a>
              </p>
              <a href="#" class="btn custom-btn custom-btn-bg-white mt-3">
                Discutez avec nous
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </div>
  </div>
</template>

<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';


export default {
  components: { Header, Footer, SideBar },
  data() {
    return {
      bonusProgress: 0,
      transactionProgress: 0,
      entreprise: "",
      telephone: "",
      adresse: "",
      logo: null,
      logoFile: null,
      previousTransactionProgress: 0,
      oldPassword: '',
      newPassword: ''
    };
  },

  mounted() {

    // Récupérer les informations de l'entreprise depuis sessionStorage
    const userData = JSON.parse(sessionStorage.getItem('userData'));
    const entrepriseInfo = userData.entreprise;

    if (entrepriseInfo) {
      this.entreprise = entrepriseInfo.entreprise;
      this.telephone = entrepriseInfo.telephone;
      this.adresse = entrepriseInfo.adresse;
    }

    axios.get('/get-bonus')
      .then(response => {
        const latestParametre = response.data.latest_parametre;
        this.startDate = latestParametre.start_date;
        this.endDate = latestParametre.end_date;
        this.transactionProgress = latestParametre.transaction_progress;
        this.bonusProgress = latestParametre.bonus_progress;
      })
      .catch(error => {
        console.error(error);
      });
  },

  methods: {
    handleLogoChange(event) {
      this.logoFile = event.target.files[0];
    },

    updateEnterprise() {
      const formData = new FormData();

      formData.append("entreprise", this.entreprise);
      formData.append("telephone", this.telephone);
      formData.append("adresse", this.adresse);
      formData.append("logo", this.logoFile);
      formData.append('_method', 'put');

      const userData = JSON.parse(sessionStorage.getItem('userData'));
      const entrepriseId = userData.entreprise.id;

      axios.post(`/entreprises/${entrepriseId}/update`, formData)
        .then(response => {
          console.log(response.data);

          // Réinitialisation des champs après la mise à jour réussie
          this.entreprise = '';
          this.telephone = '';
          this.adresse = '';
          this.logoFile = null;

          Swal.fire({
            icon: 'success',
            title: 'Mise-à-jour réussie!',
            showConfirmButton: false,
            timer: 1500
          });
        })
        .catch(error => {
          console.error(error);
        });
    },

    updatePassword() {
      const payload = {
        old_password: this.oldPassword,
        new_password: this.newPassword
      };

      const userData = JSON.parse(sessionStorage.getItem('userData'));
      const UserId = userData.id;
      axios.put(`/user-africamoney/${UserId}/update-password`, payload)
        .then(response => {
          console.log(response.data);

          Swal.fire({
            icon: 'success',
            title: 'Mot de passe mis à jour avec succès !',
            showConfirmButton: false,
            timer: 1500
          });

          this.oldPassword = '';
          this.newPassword = '';
        })
        .catch(error => {
          console.error(error);
        });
    },

    updateBonusProgress() {
      this.bonusProgress = Math.round(this.bonusProgress);
      sessionStorage.setItem('startDate', this.startDate);
      sessionStorage.setItem('endDate', this.endDate);
    },

    updateTransactionProgress() {

    },

    applyChanges() {

      const sessionData = JSON.parse(sessionStorage.getItem('userData'));
      const entrepriseId = sessionData.entreprise.id;
      const userAfricamoneyId = sessionData.entreprise.user_africamoney_id;

      axios.post('/add-bonus', {
        startDate: this.startDate,
        endDate: this.endDate,
        transactionProgress: this.transactionProgress,
        bonusProgress: this.bonusProgress,
        entreprise_id: entrepriseId,
        user_africamoney_id: userAfricamoneyId
      })
        .then(response => {
          console.log(response.data);
          Swal.fire({
            icon: 'success',
            title: 'Paramètres des bonus définis avec succès !',
            showConfirmButton: false,
            timer: 1500
          });
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};
</script>

<style></style>
